<template>
  <v-container>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
      >
        <edit-refound v-if="showEditModal" />
        <app-data-table
          :title="
            $vuetify.lang.t('$vuetify.titles.list', [
              $vuetify.lang.t('$vuetify.menu.refund')
            ])
          "
          csv-filename="Refunds"
          :headers="getTableColumns"
          :is-loading="isTableLoading"
          :items="refunds"
          :view-new-button="false"
          :viewShowFilterDate="true"
          :manager="'refunds'"
          :sort-by="['name']"
          :sort-desc="[false, true]"
          multi-sort
          @reload-data="reloadData($event)"
          @edit-row="editPaymentHandler($event)"
          @delete-row="deletePaymentHandler($event)"
        >
          <template v-slot:item.created_at="{ item }">
            {{ convertDate(item.created_at) }}
          </template>
        </app-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EditRefound from './EditRefund'
export default {
  name: 'ListPayment',
  components: {
    EditRefound
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    ...mapState('refund', ['showEditModal', 'refunds', 'isTableLoading']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.tax.noFacture'),
          value: 'sale_article_shops.sale.no_facture',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.articles.name'),
          value: 'sale_article_shops.article_shops.article.nameComplete',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.variants.cant'),
          value: 'cant',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.payment.cash'),
          value: 'money'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.date'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.created_by'),
          value: 'createBy'
        }
      ]
    }
  },
  created () {
  },
  methods: {
    ...mapActions('refund', [
      'toogleNewModal',
      'openEditModal',
      'getRefunds',
      'deleteRefund'
    ]),
    reloadData (filter) {
      this.loadInitData(filter)
    },
    async loadInitData (filter) {
      await this.getRefunds(filter)
      this.switchLoadData(false)
    },
    convertDate (dateString) {
      const date = new Date(dateString)
      const day = date.getDate()
      const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      const year = date.getFullYear()
      const hour = date.getHours()
      const minutes = date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`
      const seconds = date.getSeconds()
      return (`${day}-${month}-${year} ${hour}:${minutes}:${seconds}`)
    },
    editPaymentHandler ($event) {
      this.openEditModal($event)
    },
    deletePaymentHandler (id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.pay')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) this.deleteRefund(id)
        })
    }
  }
}
</script>

<style scoped></style>
